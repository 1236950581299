import Vue from 'vue'
import VueRouter from 'vue-router'

const EMPTY = {
    template: '<router-view />'
}
Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: '404',
        component: {
            template: '<h1>404</h1>'
        }
    },
    {
        path: '/',
        name: '首页',
        component: () => import('@/views/index/index.vue')
    },
    {
        path: '/notice',
        name: '系统公告',
        component: () => import('@/views/index/notice.vue')
    },
    {
        path: '/enterSchoolCode',
        name: '学校编码',
        component: () => import('@/views/student/enterSchoolCode.vue')
    },
    {
        path: '/buyMustSee',
        name: '购买必看',
        component: () => import('@/views/goods/buyMustSee.vue')
    },

    {
        path: '/goodsDetail/:id',
        props: true,
        name: '商品详情',
        component: () => import('@/views/goods/goodsDetail.vue')
    },
    // {
    //     path: '/cart',
    //     name: '购物车',
    //     component: () => import('@/views/cart/index.vue')
    // },
    {
        path: '/cart',
        name: '购物车',
        component: () => import('@/views/cart/newCartList.vue')
    },
    {
        path: '/test',
        name: '测试',
        component: () => import('@/views/test.vue')
    },
    {
        path: '/mine',
        name: '我的',
        component: () => import('@/views/mine/index.vue')
    }, {
        path: '/student',
        name: '学生管理',
        component: EMPTY,
        children: [{
            path: 'list',
            name: '学生管理',
            component: () => import('@/views/student/list.vue')
        },{
            path: 'newList',
            name: '学生管理',
            component: () => import('@/views/student/newList.vue')
        },{
            path: 'newStudentList',
            name: '学生管理',
            component: () => import('@/views/student/newStudentList')
        }, {
            path: 'add',
            name: '新增学生',
            meta: {type: 'add'},
            component: () => import('@/views/student/handle.vue')
        }, {
            path: 'edit/:id',
            name: '编辑学生',
            meta: {type: 'edit'},
            component: () => import('@/views/student/handle.vue')
        }]

    },

    {
        path: '/address',
        name: '地址管理',
        component: EMPTY,
        children: [{
            path: 'list',
            name: '地址管理',
            component: () => import('@/views/address/list.vue')
        }, {
            path: 'add',
            name: '新增地址',
            meta: {type: 'add'},
            component: () => import('@/views/address/handle.vue')
        }, {
            path: 'edit/:id',
            name: '编辑地址',
            meta: {type: 'edit'},
            component: () => import('@/views/address/handle.vue')
        }]
    },
    {
        path: '/order',
        component: EMPTY,
        children: [{
            path: 'submit',
            name: '提交订单',
            component: () => import('@/views/order/submitOrder.vue')
        }, {
            path: 'paySuccess',
            name: '支付成功',
            component: () => import('@/views/order/paySuccess.vue')
        }, {
            path: 'list',
            name: '我的订单',
            component: () => import('@/views/order/list.vue')
        }, {
            path: 'apiOrderList',
            name: '我的订单',
            component: () => import('@/views/order/listComponent/apiOrderList.vue')
        }, {
            path: 'detail/:id',
            name: '订单详情',
            component: () => import('@/views/order/detail.vue')
        }, {
            path: 'logistics/:id',
            name: '物流详情',
            component: () => import('@/views/order/logistics.vue')
        }, {
            path: 'allLogistics/:id',
            name: '物流详情',
            component: () => import('@/views/order/allLogistics.vue')
        }]
    },
    {
        path: '/refund',
        component: EMPTY,
        children: [{
            path: 'audit',
            name: '退款审核',
            component: () => import('@/views/refund/audit.vue')
        }, {
            path: 'create/:id',
            name: '申请退款',
            component: () => import('@/views/refund/createRefund')
        }, {
            path: 'list',
            name: '申请列表',
            component: () => import('@/views/refund/list')
        }, {
            path: 'detail/:id',
            name: '退款详情',
            component: () => import('@/views/refund/details')
        }]
    },
    {
        path: '/afterSales',
        component: EMPTY,
        children: [{
            path: 'create',
            name: '申请退款',
            component: () => import('../views/afterSales/create')
        }
        ,{
            path: 'list',
            name: '售后服务',
            component: () => import('../views/afterSales/list')
        }
        ,{
            path: 'userAudit',
            name: '售后服务',
            component: () => import('../views/afterSales/userAudit')
        }
        ,{
            path: 'detail/:id',
            name: '售后详情',
            component: () => import('../views/afterSales/detail')
        }]
    },
    {
        path: '/school',
        component: EMPTY,
        children: [{
            path: 'list',
            name: '学校更换',
            component: () => import('@/views/school/list')
        }, {
            path: 'input',
            name: '切换学校',
            component: () => import('@/views/school/input')
        }, {
            path: 'select',
            name: '选择学校',
            component: () => import('@/views/school/selectSchool')
        }]
    },
    {
        path: '/integral',
        component: EMPTY,
        children: [{
            path: 'index',
            name: '积分分润统计',
            component: () => import('@/views/integral/index')
        }, {
            path: 'orderList',
            name: '查看积分订单',
            component: () => import('@/views/integral/orderList')
        }]
    }, {
        path: '/schoolUniforms',
        name: '校服图册',
        component: () => import('@/views/schoolUniforms/index')
    }, {
        path: '/sales',
        name: '销售统计',
        component: () => import('@/views/sales/index')
    }, {
        path: '/audit',
        name: '上架商品审核',
        component: () => import('@/views/audit/index')
    }, {
        path: '/auditDetail',
        name: '上架审核',
        component: () => import('@/views/audit/detail')
    }, {
        path: '/shelves/edit',
        name: 'ShelvesEdit',
        component: () => import('@/views/shelves/edit'),
        meta: {
            title: '商品上架'
        }
    }, {
        path: '/shelves',
        component: EMPTY,
        children: [{
            path: 'list',
            name: '商品上架',
            component: () => import('@/views/shelves/list')
        }, {
            path: 'detail',
            name: '商品上架',
            component: () => import('@/views/shelves/detail')
        }, {
            path: 'setUniform',
            name: '设置包单品',
            component: () => import('@/views/shelves/setUniform')
        }, {
            path: 'setMultiform',
            name: '设置多规格',
            component: () => import('@/views/shelves/setMultiform')
        }]
    }, {
        path: '/electronicNameTicker',
        component: EMPTY,
        children: [{
            path: 'list',
            name: '电子姓名贴',
            component: () => import('@/views/electronicNameTicker/list')
        }]

    }, {
        path: '/mapPage',
        name: '售后服务',
        component: () => import('@/views/map/index')

    }, {
        path: '/management',
        component: EMPTY,
        children: [{
            path: 'login',
            name: '管理中心登录',
            component: () => import('@/views/management/login')
        }, {
            path: 'statistical',
            name: '校服订购汇总',
            component: () => import('@/views/management/statistical')
        }, {
            path: 'grade',
            name: '校服订购年级汇总',
            component: () => import('@/views/management/grade')
        }, {
            path: 'class',
            name: '校服订购班级汇总',
            component: () => import('@/views/management/class')
        }, {
            path: 'detail',
            name: '校服订购班级明细',
            component: () => import('@/views/management/detail')
        }]
    }, {
        path: '/store',
        component: EMPTY,
        children: [{
            path: 'index/:storeId',
            name: '门店',
            component: () => import('@/views/store/index')
        }, {
            path: 'student',
            name: '填写用户信息',
            meta: {type: 'add'},
            component: () => import('@/views/store/student/handle')
        }]
    }, {
        path: '/deliveryNote',
        component: EMPTY,
        children: [{
            path: 'index/:deliveryNoteId',
            name: '确认发货单',
            component: () => import('@/views/deliveryNote/index')
        }]
    },{
        path: '/protocol',
        component: EMPTY,
        children: [{
            path: 'userAgreement',
            name: '用户协议',
            component: () => import('@/views/protocol/userAgreement')
        }, {
            path: 'privacyAgreement',
            name: '隐私政策',
            component: () => import('@/views/protocol/privacyAgreement')
        }, {
            path: 'childrenSPrivacyAgreement',
            name: '儿童隐私政策',
            component: () => import('@/views/protocol/childrenSPrivacyAgreement')
        }]
    },{
        path: '/set',
        component: EMPTY,
        children: [{
            path: 'set',
            name: '设置',
            component: () => import('@/views/set/set')
        }]
    },{
        path: '/product',
        component: EMPTY,
        children: [{
            path: 'overdueList',
            name: '历史商品',
            component: () => import('@/views/product/overdueList')
        }]
    }
]


let arr = []
routes.forEach(e => {
    if (e.children) {
        e.children.forEach(e2 => {
            e2.meta = Object.assign({}, e2.meta, {title: e2.name});
            e2.name = e.path + e2.path
            e2.path = e.path + '/' + e2.path
            if (e2.name && e2.name.replaceAll) {
                e2.name = e2.name.replaceAll('/', '')
            }
            arr.push(e2)
        })
    } else {
        e.meta = Object.assign({}, e.meta, {title: e.name});
        e.name = e.path
        if (e.name && e.name.replaceAll) {
            e.name = e.name.replaceAll('/', '')
        }
        arr.push(e)
    }
})

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: arr,
})

router.afterEach((to) => {
    document.title = to.meta.title
})

export default router
